.ant-page-header-ghost {
  background-color: inherit;
}
.ant-page-header-heading {
  display: flex;
  justify-content: space-between;
}
.ant-page-header-heading-left {
  align-items: center;
  display: flex;
  margin: 4px 0px;
  overflow: hidden;
}
.ant-page-header-back {
  font-size: 16px;
  line-height: 1;
  margin-right: 16px;
}
.ant-page-header-back-button {
  font-size: 18px;
  color: rgb(0, 0, 0);
  cursor: pointer;
  outline: none;
  text-decoration: none;
  transition: color 0.3s ease 0s;
}
.ant-page-header-heading-title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 0px;
  margin-right: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ant-page-header-heading-title-secondary {
  color: rgba(0, 0, 0, 0.25);
  font-size: 18px;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-page-header-heading-extra {
  white-space: nowrap;
  margin: 4px 0px;
}
