.alert-importer-error {
  padding: 1rem;
  .ant-alert-message {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: unset;
  }
  ul {
    padding: 0;
    margin-left: 1rem;
    margin-bottom: 0;
    svg {
      color: #6b7c8b;
    }
  }
}
.alert-cannot-upload {
  padding: 0.5rem;
  margin-bottom: 0;
  .ant-alert-message {
    font-size: 14px;
    font-weight: 600;
  }
  .ant-alert-description {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: unset;
  }
}
.title-help-importer {
  color: #6b7c8b;
  font-size: 14px;
  font-weight: 400;
  svg {
    margin-right: 0.5rem;
  }
}
.card-instructions-importer {
  box-shadow: unset !important;
  border-left: 2px solid #6b7c8b;
  border-radius: unset;
  color: #6b7c8b;
  .ant-card-body {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.title-instructions-importer {
  font-size: 14px;
  font-weight: 500;
  color: #6b7c8b;
}
.ul-instructions-importer {
  padding-left: 1rem;
  padding-top: 0.5rem;
  li {
    padding-top: 0.3rem;
  }
}
.list-help-importer {
  padding: 5px 15px !important;
  color: #9825f2 !important;
  svg {
    padding-top: 3px;
    float: right;
  }
}
.list-help-importer:hover:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.list-help-importer:hover:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.list-help-importer:hover {
  background-color: #f5f5f5;
  cursor: pointer;
  transform: scale(1.001);
}
.btn-help-importer {
  color: #6b7c8b;
  svg {
    margin-right: 0.5rem;
  }
}
.card-uploading {
  border: 1px dashed #d9d9d9;
  .col-spinner {
    padding-top: 0.5rem;
    svg {
      color: #d9d9d9;
    }
  }
}
.card-uploaded-success {
  border-color: #d9d9d9;
  margin-bottom: 1rem;
  .icon-file {
    margin-right: 0.5rem;
    color: #1890ff;
  }
  .icon-trash {
    color: #00000073;
  }
}
.card-uploaded-error {
  border-color: #e30001;
  color: #e30001;
  margin-bottom: 1rem;
  .icon-file {
    margin-right: 0.5rem;
  }
  .icon-trash {
    color: #00000073;
  }
}
.btn-upload-importer {
  margin-bottom: 1rem;
}
.card-detail-errors {
  margin: 1rem;
  border-color: #e0e5ea;
  .ant-card-head-title {
    color: #5d5d5d;
  }
  .ant-card-body {
    padding: 0.5rem;
    padding-top: 0;
    border-top-color: white;
    border-top-style: outset;
    color: #838383;
    ul {
      margin-bottom: 0.5rem;
      svg {
        padding-right: 0.5rem;
        color: #6b7c8b;
      }
    }
  }
}
