.leaflet-container {
  width: 100%;
  min-height: 872px;
  background: rgb(255, 255, 255);
  position: relative;
}
.map-card {
  position: absolute;
  z-index: 1000;
  margin-top: 20px;
  left: 0;
  right: 0;
}
.mid-col-popup {
  width: 15px;
  display: block;
}
.custom-button-fullscreen {
  padding-left: 10px;
  padding-right: 10px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}
/* Defined !important coz otherwise it won't take the width of the container */
.leaflet-popup-content {
  width: 100% !important; 
}
.popup-content-container {
  width: 180px;
}
.popover-content-container {
  width: 280px;
}
.popup-extended-content-container {
  position: relative;
  width: 650px;
  height: 290px;
}
.popup-extended-content-container
.top-container{
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

}
.popup-extended-content-container
.end-container{
  height: 45px;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: white;
  z-index: 5;
}
.event-percentage-row.extended {
  margin: 10px 0 5px 0;
  width: 100%;
  height: 10px;
}
.popup-show-route-btn {
  display: flex;
  width: 100%;
  justify-content: right;
}
