.mobile-state-background {
  background-color: #f1f1f1;
  min-height: 300px;
  padding-top: 1px;
  border-radius: 8px;
}

.state-segment > div > label {
  min-width: 120px;
}

.state-container {
  margin: 1rem;
  width: 100%;
}

.state-container .ant-tabs-tab-active > .ant-tabs-tab-btn {
  color: #000 !important;
  text-shadow: unset !important;
}
