.base-synoptic-style{
  max-height: 100%;
  width: 100%;
  background: #f5f5f5;
}

.base-synoptic-style
.sticky-element{
  position: sticky;
}

.base-synoptic-style
.sticky-element.column-data{
  left: 0;
  z-index: 1;
}

.base-synoptic-style
.sticky-element.header{
  top: 0;
  margin-bottom: -4px;
}

.base-synoptic-style
.header.data-area{
  z-index: 2;
}

.chevron-button {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(50%, 125%);
  z-index: 9999;
  border-radius: 50%;
}

.divider {
  height: 4rem;
  display: flex;
  align-items: center;
  background-color: white;
  & span {
    font-weight: normal;
    font-size: 18px;
  }
}

.routing-sets-container {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.actions {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 0.75rem;
  justify-content: flex-end;
  padding-right: 0.75rem;
}

.status-data {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.popover-content {
  display: flex;
  gap: 0.75rem;
  flex-direction: column;
  padding: 0.25rem;
}

.pointer {
  cursor: pointer;
}
