.sidebar.mobile-sidebar {
  padding-top: 1rem;
  @media (min-width: 767px) {
    display: none;
  }
  .ant-menu-item {
    color: #474747;
    font-size: 14px;
  }
  .ant-menu-item .hover,
  .ant-menu-item:hover {
    color: #9825f2 !important;
  }
  li:last-child {
    bottom: 0;
    height: 70px;
    width: auto;
    padding-right: 0;
  }
  .image-logo-mobile {
    cursor: pointer;
    object-fit: contain;
    max-height: 65px;
    aspect-ratio: 16 / 9;
  }
}
