.text-panel-description {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 400;
}

.panel-badge-error {
  margin-top: 14px;
  margin-right: 14px;
}

.panel-card {
  max-width: 240px;
  max-height: 310px;
  margin-bottom: 1rem;
  @media (max-width: 767px) {
    max-width: unset;
    max-height: unset;
  }
}

.panel-list-container {
  min-height: 55vh;
  max-height: 60vh;
  overflow: auto;
}
.image-panel {
  width: 220px !important;
  height: 145px !important;
  @media (max-width: 767px) {
    width: 60vw !important;
    height: unset !important;
  }
}
.image-panel.is-popover {
  width: 300px !important;
  height: 155 !important;
}

.report-filter-col {
  border-right: 1px #999 solid;
  min-height: 76vh;
  @media (max-width: 767px) {
    border-right: unset;
  }
}
