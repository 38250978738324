.right-panel {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.site-layout-content {
  min-height: calc(100vh - 164px);
  max-width: calc(100vw - 165px);
  padding: 24px;
  background: #fff;
  border-radius: 8px;
  @media (max-width: 767px) {
    max-width: unset;
  }
}

.site-layout-content-secondary {
  min-height: calc(100vh - 164px);
  padding: 24px;
  background: #fff;
  border-radius: 8px;
}

.ant-layout.ant-layout-has-sider {
  flex-direction: column;
}
