/* SUBNAVBAR */
.subnavbar {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  height: 49px;
}

.subnavbar > li.ant-menu-item-selected{
  font-weight: 700;
}

.subnavbar div {
  display: inherit;
  align-items: center;
}

.tab-option-selected {
  color: #0d63cf;
  flex-direction: column;
}

.title {
  height: 100%;
}

.guide-line {
  border-bottom: 3px solid #0d63cf;
  width: 150px;
}

.subnavbar p {
  font-size: 14px;
  line-height: 13px;
  padding-left: 5px;
  margin-bottom: 0px;
}
