.form-login-container {
  width: 350px;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  margin-top: 80px;
}

.form-login-logo {
  margin-bottom: 50px;
  max-width: 250px;
  text-align: center;
}

.form-login-forgot-password {
  text-align: center;
  text-decoration: underline;
}

.login-left-content span {
  color: white;
  position: absolute;
  width: 100%;
  bottom: 10px;
  text-align: center;
}

.login-left-content a {
  color: white;
  text-decoration: underline;
}
