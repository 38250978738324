.depot-text-table,
.client-text-table,
.name-text-driver-table {
  width: 100px;
}
.color-positive-text,
.color-positive-gps {
  color: #52c41a;
}
.color-negative-gps {
  color: #fa8c16;
}
.color-negative-text {
  color: #d00101;
}
.container-planned-stops thead.ant-table-thead .ant-table-cell {
  background-color: #fff;
  border: none;
  font-weight: 400;
}
.container-planned-stops .ant-table-tbody > tr > td,
.container-planned-stops .ant-table-thead > tr > th,
.container-planned-stops .ant-table-thead > tr > th::before,
.container-planned-stops .ant-table-thead > tr > td::before {
  width: auto !important;
  border: none;
}
.card-states-routes .ant-card-body {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
}
.card-states-routes .title-states {
  margin-bottom: unset;
  font-size: 23px;
}
.card-states-routes .subtitle-states {
  font-size: 12px;
  line-height: 1;
}
.assign-titles {
  font-size: 18px !important;
  font-weight: 500 !important;
  margin-bottom: unset !important;
}
.cascader-drawer-following,
.select-drawer {
  z-index: 2005!important;
}
.filter-drawer .ant-drawer-body {
  padding-top: 10px;
}
.filter-drawer .ant-drawer-body .ant-form-item {
  margin-bottom: 10px;
}

.images-events .slick-slide.slick-active.slick-current {
  text-align: center;
  background-color: #f9f9f9;
}

.row-timeline-container {
  margin-top: 1.5rem;
  max-height: 50vh;
}

.timeline-container {
  min-height: 200px;
  max-height: 50vh;
  overflow: auto;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  padding-top: 1rem;
}

.event-percentage-row {
  width: 140px;
  height: 10px;
}

.event-percentage-row .ant-col:first-child {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.event-percentage-row .ant-col:last-child {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.following-left-component {
  border-right: 1px #999 solid;
  @media (max-width: 767px) {
    border-right: unset;
  }
}

.responsive-picker.ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
  @media (max-width: 767px) {
    display: grid;
  }
}
