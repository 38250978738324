.driver.col-logout {
  text-align: right;
  .mobile-btn {
    width: 100%;
  }
}
.driver.col-info {
  padding-top: 3rem;
  text-align: justify;
  font-size: 16px;
  line-height: 22px;
  .select-scan {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
.driver.col-rqs {
  text-align: center;
  margin-bottom: 1rem;
}
.qr-image {
  display: block;
}
.driver.app-preview {
  @media (max-width: 767px) {
    margin-top: 3rem;
  }
}
.driver.col-footer {
  text-align: center;
  margin-top: 1rem;
}
