* {
  font-family: Roboto, Arial, sans-serif;
  margin: 0px;
}

html,
body {
  height: 100vh;
  width: 100vw;
}

.app {
  width: 100vw;
  display: flex;
  flex-direction: row;
  background: #f0f2f5;
}

/* ### ICONO DEL POP UP: ESTA SEGURO QUE DESEA ARCHIVAR? ##### */
.ant-result-error .ant-result-icon > .anticon {
  background: #e30001;
  border: 1px solid #e30001;
  border-radius: 50%;
  color: #fff;
}

/* ### TEXT POPUP MODAL SIZE ##### */
.ant-result-title {
  font-size: 16px;
}

.btn-no-shadow {
  box-shadow: none;
}

.float-right {
  float: right;
}

.footer-drawer-buttons {
  float: right;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}

.ant-input-wrapper.ant-input-group > input {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.padding-page-header,
.site-page-header {
  padding-left: unset;
  padding-top: unset;
  padding-right: unset;
}

.ant-progress-bg {
  background-color: #d39e00;
}

.timepicker-width div.ant-picker-panel {
  width: 150px;
}

.new-routingset-button li {
  padding: 0 !important;
}

.ant-modal-title .importer-modal > .ant-flex {
  font-size: 18px;
}
