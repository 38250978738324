.notification-meta {
  .ant-card-meta-avatar {
    margin-top: 0.5rem;
    padding-inline-end: 0.5rem;
    padding-left: 0.5rem;
  }
  .ant-card-meta-detail {
    .ant-card-meta-title {
      margin-bottom: 0 !important;
      font-size: 14px;
      font-weight: 500;
    }
    .ant-card-meta-description {
      font-size: 12px;
      color: #454545;
      .date-created {
        font-size: 13px;
        font-weight: 400;
        float: right;
        color: #999;
        padding-right: 1rem;
      }
    }
  }
}

.notification-popover {
  .ant-popover-content {
    .ant-popover-inner {
      width: 392px;
      max-height: 500px;

      .ant-popover-title {
        font-size: 18px;
        font-weight: 500;
        border-bottom: 1px solid #d9d9d9;
      }
      .ant-popover-inner-content {
        max-height: 400px;
        overflow-x: hidden;
        .ant-card:hover {
          background-color: #f5f5f5;
        }
      }
    }
    .notification-subtitle {
      font-size: 16px;
      font-weight: 500;
    }
  }
}
