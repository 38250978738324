.ant-card-assign {
  width: '100%';
  align-items: 'center';
}

.ant-typography-assign {
  margin-bottom: 0 !important;
  font-size: 12px !important;
}

.ant-avatat-assign-button,
.ant-avatat-assign {
  border-width: 1px !important;
  border-style: solid !important;
}

.ant-avatat-assign {
  margin-top: 10px !important;
}
