.profile-button {
  padding-top: 3px!important;
  padding-bottom: 4px!important;
  padding-left: 12px!important;
  padding-right: 12px!important;
  font-size: 14px!important;
  max-width: 235px!important;
  width: 235px!important;
}
.profile-button > .ant-avatar {
  margin-right: 11px;
}

.profile-button > .name-text {
  max-width: 145px;
  width: 145px;
}

.profile-button > .chevron-icon {
  margin-left: 10px;
  color: #00000040;
}

.change-password-drawer-body {
  width: 300px;
  margin-left: 35px!important;
}

.drawer-container {
  margin: 0 60px 0 60px;
}

.drawer-avatar {
  margin: 50px 0 20px 0;
}
