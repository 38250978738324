.ant-radio-button-wrapper-checked > span > button {
  color: #fff!important;
}

.ant-radio-button-wrapper-checked > span > button:hover {
  background-color: #3F96FE!important;
}

.ant-radio-button-wrapper > span > button:hover {
  background-color: #fff;
}