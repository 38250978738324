.card-style {
  width: 240px;
}

.card-cover-style {
  height: 100px;
  object-fit: contain;
}

.description-card-style {
  opacity: 0.4;
  width: 150px;
}

.list-style {
  padding: 0;
}

.create-button-style {
  color: #ababab;
  height: 263px;
  width: 240px;
  border: 1px dashed #dbdbdb;
  background: #fafafa;
}

.font-awesome-icon-style {
  margin-bottom: 15px;
}

.list-description-container-style {
  color: #000000;
}
