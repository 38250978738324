.icon-form {
  text-align: center;
  margin-top: 8%;
  margin-bottom: 5%;
}

.login-item {
  width: 100%;
}

.login-form-forgot {
  margin-top: 10%;
}

.strength-meter {
  padding: 8px 16px;
  border-bottom: none !important;
  transition: none !important;
}

.account-left-content {
  justify-content: center;
  margin-left: 40%;
  margin-top: 8%;
}

.confirm-account-title {
  text-align: center;
  padding-bottom: 10%;
}

.forgot-password-subtitle {
  margin-bottom: 50px;
}

.recover-password-title {
  padding-bottom: 10%;
  margin: auto;
}

.forgot-password-title {
  padding-bottom: 10%;
  margin: auto;
}
