/* SIDEBAR */
.sidebar {
  background-color: #6638b3;
  min-height: 100vh;
  width: 116px;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
}
.sidebar.web-sidebar {
  @media (max-width: 767px) {
    display: none;
  }
}

.sidebar-logo {
  display: flex;
  padding-top: 20px;
  justify-content: center;
  margin-bottom: 40px;
}

.logo {
  width: 45.32px;
  height: 46.94px;
  top: 5.38px;
  left: 9.74px;
}

.sidebar-icons {
  display: flex;
  flex-direction: column;
  margin-top: 3px;
}

.sidebar-button {
  display: flex;
  justify-content: center;
  padding: 5px;
  margin: 5px 10px 5px 10px;
  border-radius: 8px;
}

.sidebar-icons a {
  color: white;
  text-decoration: none;
}

.sidebar-icons p {
  color: white;
  line-height: 13px;
  font-weight: 400;
  margin: 10px;
}
.sidebar-icons hr {
  margin-top: 40px;
}

.hover {
  background: rgba(255, 255, 255, 0.33);
}
