/* DemandsSection */
.ant-page-header {
  padding: 16px 24px 16px 0px;
}

.demand-details-title {
  margin-bottom: 1.25rem;
}

.resume-info {
  margin-bottom: 5px;
}

.tooltip-information {
  cursor: pointer;
}

.demands-sets-filters {
  justify-content: flex-end;
  margin-left: auto;
  margin-bottom: 20px;
  width: 450px;
}
/* DemandsClientDetails */
.search-button-row {
  align-items: center;
}

.search-button {
  width: 70%; 
}
.dynamic-delete-button {
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}

