.container {
  display: flex;
  flex-direction: column;
}
/* NAVBAR */
.navbar {
  border-bottom: 1px solid #c1c1c1;
  display: flex;
  height: 67px;
  align-items: center;
  background: #ffffff;
  @media (max-width: 767px) {
    border-bottom: unset;
  }
}

.navbar h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 13px;
  margin-left: 20px;
  margin-top: 10px;
}

.navbar a {
  color: inherit;
  text-decoration: inherit;
}

.vertical-line {
  border: 1px solid #999999;
  height: 50px;
  margin-left: 20px;
}

.new-route {
  margin-left: 35px;
}

.new-route i {
  padding-right: 4px;
}

.search span {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.search i {
  text-align: right;
  padding-left: 150px;
}
.search button {
  border-left: unset;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.ant-select-selector,
.ant-input-affix-wrapper,
.ant-input-search-button {
  border-color: #c1c1c1 !important;
}

.flag {
  border: 1px solid #c1c1c1;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  height: 38px;
  width: 70px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notifications {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  background: #fff;
  color: black;
}

.profile {
  width: 170px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.profile > p {
  margin-bottom: inherit;
}

.profile-pic {
  width: 34px;
  height: 34px;
  border-radius: 100%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
}

.search-container .ant-input-affix-wrapper {
  height: 40px;
}

.ant-popover {
  width: 280px;
}

.image-logo {
  cursor: pointer;
  object-fit: contain;
  max-height: 65px;
  margin-right: 10px;
  aspect-ratio: 16 / 9;
}
.client-logo-container {
  transition: transform 0.2s;
}

.client-logo-container:hover {
  opacity: 0.9;
  transform: scale(1.03);
}

.language-select {
  border-color: #d9d9d9;
}

.logo-mobile {
  margin-left: 1rem;
}
.name-module-responsive {
  flex: auto;
  background-color: #fff;
  font-size: 20px;
  font-weight: 700;
  padding-left: 1rem;
}
