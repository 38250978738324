.item-help:hover {
  background-color: #f5f5f5;
  cursor: pointer;
  transform: scale(1.03);
}
.help-group {
  margin-bottom: 0.5rem;
}
.help-list {
  height: 65vh;
  overflow: auto;
  @media (max-width: 767px) {
    height: 55vh;
  }
}
.help-title {
  @media (max-width: 767px) {
    font-size: 22px !important;
  }
}
.help-tag-title {
  @media (max-width: 767px) {
    font-size: 18px !important;
  }
}
.result-help {
  margin-top: 0;
  background-color: white;
}
.detail-help-content {
  height: 45vh;
  overflow: auto;
}
.help-list-item {
  margin-top: 20px;
}
.help-howtoimport-image1 {
  width: 85%;
}
.help-howtoimport-image2 {
  width: 70%;
}
.help-ol {
  padding-left: 1rem;
}
.help-ol > li::marker {
  font-weight: bold;
}
.help-li {
  margin-top: 1rem;
}
.help-structure-table-image {
  object-fit: contain;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.help-table-image1 {
  width: 65%;
}
.help-table-image2 {
  width: 85%;
}
.help-table {
  margin-top: 3rem;
  border: 1px solid #d9d9d9;
}
.help-table-title {
  border-bottom: 1px solid #d9d9d9;
  background-color: #f2f2f2;
  text-align: center;
  padding: 1rem;
}
.help-table-first-cell {
  border-bottom: 1px solid #d9d9d9;
  padding: 1rem;
}
.help-table-right-cell {
  border-left: 1px solid #d9d9d9;
}
.help-table-item {
  padding: 1rem;
}
.help-back-button {
  float: right;
}

@media screen and (max-width: 991px) {
  .help-table-right-title {
    border-top: 1px solid #d9d9d9;
  }
  .help-table-right-cell {
    border-left: 0;
  }
  .help-howtoimport-image1 {
    width: 100%;
  }
  .help-howtoimport-image2 {
    width: 100%;
  }
  .help-table-image1 {
    width: 90%;
  }
  .help-table-image2 {
    width: 90%;
  }
}
