:root {
  --yellow: #faad14;
  --gray: #848199;
  --primary: #9825f2;
}

.optimal-logo {
  max-width: 250px;
}

.basic-text {
  color: var(--gray);
}

.base-plan-title {
  color: var(--gray);
  font-weight: bold;
}

.medium-plan-title {
  color: var(--primary);
  font-weight: bold;
}

.most-popular-tag {
  margin-inline-end: 0px;
}

.pricing-table-container .pricing-header h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: 500;
  line-height: 35px;
}

.pricing-table-container .pricing-sub-header {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  color: #999999;
}

.pricing-table-container h2 {
  margin-right: 32px;
  font-size: 24px;
}

.pricing-table {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: 32px;
}

.pricing-table .table {
  width: 400px;
  padding: 32px;
  position: relative;
  margin-right: 20px;
}

.pricing-table .table:hover {
  box-shadow: 0px 4px 18px 0px #a66fff5e;
  transform: scale(1.05);
  transition: transform 0.3s ease;
}

.pricing-table h3 {
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 10px;
}

.pricing-table .price-container {
  display: flex;
  align-items: center;
}

.pricing-table .description {
  color: var(--gray);
}

.pricing-table .table .content h3 {
  text-align: center;
  color: #000;
}

.pricing-table .table .content {
  z-index: 200;
  position: relative;
}

.pricing-table .table .btn {
  width: 100%;
  margin-bottom: 10px;
}

.pricing-table .table .description {
  color: var(--gray);
  margin-bottom: 32px;
  text-align: center;
}

.pricing-table .table.table-medium {
  background: #fff;
  color: var(--gray);
  border-radius: 8px;
  border: 1px solid var(--primary);
  height: 718px;
}

.pricing-table .table.table-medium .value {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 4px;
  border-radius: 8px 8px 0 0;
  background: var(--primary);
  font-weight: bold;
}

.pricing-table .table.table-base {
  background: #fff;
  border-radius: 8px;
  border: 1px solid #999;
  height: 718px;
}

.pricing-table .table.table-base .content {
  color: var(--gray);
}

.pricing-table .table.table-base .value {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 4px;
  border-radius: 8px 8px 0 0;
  background: #999;
  font-weight: bold;
}

.table-features {
  color: var(--gray);
}

.pricing-table .table.table-full {
  background: #fff;
  color: var(--gray);
  border-radius: 8px;
  border: 1px solid var(--yellow);
  height: 718px;
}

.pricing-table .table.table-full .value {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 4px;
  border-radius: 8px 8px 0 0;
  background: var(--yellow);
  font-weight: bold;
}

.table-features-medium-check {
  color: var(--primary);
}

.table-features-full-check {
  color: var(--yellow);
}

.plans-features-list {
  border: 0 !important;
}
