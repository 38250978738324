.footer-button {
  width: 20%;
  margin: 3% 2%;
}

.title-route-resume-card {
  font-size: 12px;
  overflow-wrap: break-word;
  color: #999;
}

.value-route-resume-card {
  font-weight: 400;
  font-size: 18px;
}

.diff-positive-value-route-resume-card {
  font-size: 12px;
  color: #e30001;
}

.diff-negative-value-route-resume-card {
  font-size: 12px;
  color: #52c41a;
}

.header-color-route {
  padding: 1px !important;
}

.name-text-driver {
  max-width: 90px !important;
}

.color-assign-disabled {
  color: #dfdfdf;
}

.demands-container {
  position: relative;
  height: 1080px;
  max-height: 1080px;
}

.upper-container {
  transform-origin: top;
}

.upper-container .table-element {
  height: 100%;
  transform-origin: left;
}

.upper-container .map-element {
  transform-origin: right;
  height: 100%;
  width: 40%;
  overflow: auto;
}

.lower-container {
  margin-top: 15px;
  transform-origin: top;
}

.lower-control-bar {
  height: 10%;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: white;
  z-index: 5;
}
.button-no-hover:hover,
.button-no-hover:active {
  background-color: unset !important;
}
